import React from 'react';
import Items from '@/components/Items';

const App: React.FC = () => {
  return (
    <div className='w-screen h-screen flex items-center justify-center bg-gray-100'>
      <Items />
    </div>
  );
};

export default App;
