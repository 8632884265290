import { Bug, Lollipop, Repeat } from 'lucide-react';
import React from 'react';

interface Item {
  title: string;
  description: string;
  image: JSX.Element;
  link: string;
}

const items: Item[] = [
  {
    title: 'Platform (Beta)',
    description: 'Manage and send prints',
    image: <Lollipop className='h-8 w-8 text-white' />,
    link: 'https://beta.batch.works',
  },
  {
    title: 'Handler (Alpha)',
    description: 'Automate or Manipulate GCode files',
    image: <Repeat className='h-8 w-8 text-white' />,
    link: 'https://batch-print-automator.onrender.com/',
  },
  {
    title: 'Bug Reporter',
    description: 'Report problems, suggest features',
    image: <Bug className='h-8 w-8 text-white' />,
    link: 'https://bug-reporter.onrender.com/',
  },
  // Add more items as needed
];

const Items: React.FC = () => {
  return (
    <div className="flex flex-row gap-8">
      {items.map((item, index) => (
        
        <a href={item.link} target="_blank" rel="noopener noreferrer"
          className="block relative flex-shrink-0 w-80 h-80 bg-black/90 rounded-2xl p-2 overflow-hidden shadow-xl text-center transition-all duration-200 
            ease-batch-ease hover:bg-blue-800 hover:shadow-3xl"
          key={index}
        >
            <div className='absolute w-full h-full'>
            </div>
          <div className="relative w-full h-full flex flex-col gap-2 items-center justify-center">
            {item.image}
            <h3 className="text-md font-semibold text-white">{item.title}</h3>
            <p className="text-xs text-neutral-300">{item.description}</p>
          </div>
        </a>
      ))}
    </div>
  );
};

export default Items;
